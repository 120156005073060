import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { contractSocketActionType } from '../../../actions/constants';
import { contractSocket } from '../../../lib/sockets/sockets';

const UPDATED_SIGNATURE_ACTION = 'ELECTRONIC_SIGNATURE_UPDATED';

export const useContractSocket = ({ refresh }) => {
    const { contractId } = useParams();

    const socketActionHandler = useCallback((action) => {
        if (action.type === UPDATED_SIGNATURE_ACTION) {
            refresh();
        }
    }, []);

    // called when the socket connection is established
    // at this moment we emit the action to join the contract group
    // and we define th event listener for the socket actions.
    // We do this here because if the server gets disconnected, a new connection will be established
    // and in that case we need to re-join the contract group and add the action event listener again
    const onConnected = useCallback(() => {
        contractSocket.emit('action', { type: `${contractSocketActionType}/JOIN`, contractId });
        contractSocket.on('action', socketActionHandler);
    }, []);

    // called when the socket connection, previously established, gets interrupted.
    // you can simulate this by restarting the sync server
    // We are removing the action event listener to avoid unexpected behavior, its
    // added back when the connection is re-established
    const onServerDisconnected = useCallback(() => {
        contractSocket.off('action', socketActionHandler);
    }, []);

    // called when the component is unmounted (or if you want to disconnect the socket manually)
    // important to clean all the event listeners or else unexpected stuff will happen
    const disconnect = useCallback(() => {
        contractSocket.emit('action', {
            type: `${contractSocketActionType}/LEAVE`,
            contractId,
        });
        contractSocket.off('action', socketActionHandler);
        contractSocket.off('disconnect', onServerDisconnected);
        contractSocket.off('connect', onConnected);
        contractSocket.disconnect();
    }, []);

    const connect = useCallback(() => {
        contractSocket.connect();

        contractSocket.on('disconnect', onServerDisconnected);
        contractSocket.on('connect', onConnected);
    }, []);

    useEffect(() => {
        connect();

        return () => {
            disconnect();
        };
    }, []);
};
